import { unregister } from "./serviceWorker";

import  {
 useEffect,
 useState,
 useRef,
} from "react";

import "firebase/firestore";

import "firebase/auth";
import "firebase/storage";
import "firebase/firestore";

import { initializeApp } from "firebase/app";
import {
 getFirestore,
 collection,
 onSnapshot,
} from "firebase/firestore";
// import firebase from "firebase/app";

import { toast } from "react-toastify";

function VersionCheck() {
 const [siteVersionData, setSiteVersionData] =
  useState("");

 const isInitialMount = useRef(true);

 const firebaseConfig = {
  apiKey: "AIzaSyAEoOdFyXjcPXm0vwpO99xbu6a_2rWIqWw",
  authDomain: "ibc-tips.firebaseapp.com",
  projectId: "ibc-tips",
  storageBucket: "ibc-tips.appspot.com",
  messagingSenderId: "580374112182",
  appId: "1:580374112182:web:d9b1556b5dd80050414317",
  measurementId: "G-M7M2YYRFWC",
 };

 // Initialize Firebase
 const app = initializeApp(firebaseConfig);

 function showNotification(e) {
  toast(
   "A new version of this website is available, please reload after saving any work to load new website content.",
   {
    position: "top-right",
    autoClose: false,
    containerId: 1,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    onClose: () => unregister,
    draggable: true,
   }
  );
 }

 const db = getFirestore(app);

 useEffect(() => {
  if (isInitialMount.current) {
   isInitialMount.current = false;
   async function getVersion(db) {
    const versionCol = collection(db, "Public");
    onSnapshot(versionCol, (snapshot) => {
     const version = snapshot.docs[0].data();
     //  console.log(version);
     if (version) {
      let concData = JSON.parse(
       JSON.stringify(version)
      ).version;

      setSiteVersionData(version);

      if (!localStorage.getItem("appVersion")) {
       localStorage.setItem("appVersion", concData);
      } else if (
       localStorage.getItem("appVersion") !== concData
      ) {
       showNotification();
       if (caches) {
        caches.keys().then(function (names) {
         for (let name of names) caches.delete(name);
        });
        localStorage.setItem("appVersion", concData);
       }
      } else {
      }

      //   console.log(localStorage.getItem("appVersion"));
      //   console.log(concData);
     }
    });

    //   const versionList = versionSnapshot.docChanges(
    //    (docs) => {
    //     console.log(docs);
    //     docs.map((doc) => doc.data());
    //     return versionList;
    //    }
    //   );
    //  }

    //    console.log(listener);
   }

   getVersion(db).then(() => {
    // console.log(siteVersionData);
   });
  }
 }, [db, siteVersionData]);

 return null;
}
export default VersionCheck;
