import "./App.css";
import React from "react";

function App() {
 return (
  <div
   style={{
    width: "100vw",
    height: "100vh",
    background: "url(/web_bg.webp)",
    backgroundPosition: "center",
    backgroundSize: "cover",
    position: "fixed",
   }}
  >
   <div className="App-header">
    <h1>ibc.tips</h1>
    <h4
     style={{
      maxWidth: "900px",
      fontWeight: 500,
      textIndent: "25px",
      textAlign: "center",
     }}
    >
     A Discord tipping bot that enables decentralized
     p2p IBC transactions
    </h4>
    <h4
     style={{
      maxWidth: "900px",
      fontWeight: 500,
      textIndent: "25px",
     }}
    >
     Powered by Juno, Signed by Keplr
    </h4>
   </div>
  </div>
 );
}

export default App;
